import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    contactDetailsList: [],
    emailList: ["Input"],
    contactList: ["Cinput"],
    selectedExpertId: "",
    loadingList: false,
    loadingdetails: {
        type: "",
        load: false
    },
    tab: "1",
    edit: { tabIndex: "1", index: null, edit: false, value: "" },
    editc: { tabIndex: "1", index: null, edit: false, value: "" },
    viewAddC: false,
    viewAdd: false,
    value: "",
    valuec: {
        code: "",
        contact: "",
        full_contact: ""
    },
    delIndex: "",
    delIndexc: "",
    selectedItem: ""
}

const expertSlice = createSlice({
    name: "expertSlice",
    initialState: initialState,
    reducers: {
        setIsOpen: (state, { payload }) => {
            state.isOpen = payload
        },
        setLoadingList: (state, { payload }) => {
            state.loadingList = payload
        },
        setTab: (state, { payload }) => {
            state.tab = payload
        },
        setDelIndex: (state, { payload }) => {
            state.delIndex = payload
        },
        setDelIndexc: (state, { payload }) => {
            state.delIndexc = payload
        },
        setEditc: (state, { payload }) => {
            state.editc = payload
        },
        setSelectedItem: (state, { payload }) => {
            state.selectedItem = payload
        },
        setLoadingDetails: (state, { payload }) => {
            state.loadingdetails = payload
        },
        setEdit: (state, { payload }) => {
            state.edit = payload
        },
        setViewAdd: (state, { payload }) => {
            state.viewAdd = payload
        },
        setValue: (state, { payload }) => {
            state.value = payload
        },
        setValuec: (state, { payload }) => {
            state.valuec = payload
        },
        setViewAddC: (state, { payload }) => {
            state.viewAddC = payload
        },
        setEmailList: (state, { payload }) => {
            state.emailList = payload
        },
        setContactList: (state, { payload }) => {
            state.contactList = payload
        },
        setContactDetailsList: (state, { payload }) => {
            state.contactDetailsList = payload
        },
        setSelectedExpertId: (state, { payload }) => {
            state.selectedExpertId = payload
        },
        resetAll: (state) => {
            state.tab = "1"
            state.edit = { tabIndex: "1", index: null, edit: false, value: "" }
            state.editc = { tabIndex: "1", index: null, edit: false, value: "" }
            state.viewAddC = false
            state.viewAdd = false
            state.value = ""
            state.valuec = {
                code: "",
                contact: "",
                full_contact: ""
            }
            state.delIndex = ""
            state.delIndexc = ""
            state.contactDetailsList = []
            state.emailList = ["Input"]
            state.contactList = ["Cinput"]
            state.selectedExpertId = ""
            state.loadingList = false
        },
        resetOnSubmit: (state) => {
            state.edit = { tabIndex: "1", index: null, edit: false, value: "" }
            state.editc = { tabIndex: "1", index: null, edit: false, value: "" }
            state.viewAddC = false
            state.viewAdd = false
            state.value = ""
            state.valuec = {
                code: "",
                contact: "",
                full_contact: ""
            }
            state.delIndex = ""
            state.delIndexc = ""
            state.loadingList = false

        }
    }
})

export const expertReducer = expertSlice.reducer
export const { setIsOpen, setTab, setContactDetailsList, setSelectedExpertId, setEmailList, setContactList, setEditc, resetOnSubmit, resetAll,
    setEdit, setViewAdd, setSelectedItem, setLoadingList, setViewAddC, setValue, setValuec, setLoadingDetails, setDelIndex, setDelIndexc } = expertSlice.actions