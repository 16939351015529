import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isCheckboxChecked: false,
    isMediatorCheckboxChecked: false,
    autoRecordingCheckboxChecked: true,
    isExpertCheckboxChecked: false,
    meetingLinkCheckboxChecked: false,
    billingPartyCheckboxChecked: true,
    msTeamCheckboxCheck: false,
    zoomAccountList: [],
    zoomExpertList: [],
    allCompanyList: [],
    zoomHostAccountList: [],
    zoomCompanyList: [],
    userProjectList: [],
    step1: {
        engagement_type: "micro_consulting_call",
        location_address: "",
        project_name: "",
        call_name: "",
        meeting_link: "",
        meeting_id: "",
        meeting_passcode: "",
        project_id: "",
        call_date: "",
        call_time: "",
        call_time_string: "",
        contact_number: "",
        contact_code: "",
        country: {},
        timezone: "",
        abbreviation: "",
        duration: "",
        hourly_rate_call_cunsltn: "",
        hourly_currency: "USD",
        zoom_id: "",
        client: "",
        billing_client: ""
    },
    step3: [{
        expert_name: null,
        expert_designation: null,
        expert_company: null,
        expert_email: null,
        expert_hourly_rate_call_cunsltn: "",
        expert_hourly_currency: "USD",
        country: {},
        timezone: "",
        abbreviation: "",
        additional_details: "",
        opt_out_check: false,
        in_list: true,
        pre_call_email_sent: false
    }],
    inputs: [
        {
            client_name: "",
            client_email: "",
            client: "",
            in_list: true,

        },
    ],
    mediators: [
        {
            mediator_email: "",
            mediator_name: "",
            mediator_country: "",
            mediator_timezone: "",
            abbreviation: "",
        },
    ],
    allResult: []
}

const zoomMeetingSlice = createSlice({
    name: "zoomMeetings",
    initialState: initialState,
    reducers: {
        setIsCheckboxChecked: (state, { payload }) => {
            state.isCheckboxChecked = payload
        },
        setIsMediatorCheckboxChecked: (state, { payload }) => {
            state.isMediatorCheckboxChecked = payload
        },
        setIsAutoRecordingCheckboxChecked: (state, { payload }) => {
            state.autoRecordingCheckboxChecked = payload
        },
        setIsMeetingLinkCheckboxChecked: (state, { payload }) => {
            state.meetingLinkCheckboxChecked = payload
        },
        setIsBillingPartyCheckboxChecked: (state, { payload }) => {
            state.billingPartyCheckboxChecked = payload
        },
        setMsTeamCheckboxChecked: (state, { payload }) => {
            state.msTeamCheckboxCheck = payload
        },
        setIsExpertCheckboxChecked: (state, { payload }) => {
            state.isExpertCheckboxChecked = payload
        },
        setZoomAccountList: (state, { payload }) => {
            state.zoomAccountList = payload
        },
        setAllCompanyList: (state, { payload }) => {
            state.allCompanyList = payload
        },
        setZoomExpertList: (state, { payload }) => {
            state.zoomExpertList = payload
        },
        setUserProjectList: (state, { payload }) => {
            state.userProjectList = payload
        },
        setZoomHostAccountList: (state, { payload }) => {
            state.zoomHostAccountList = payload
        },
        setStep1: (state, { payload }) => {
            state.step1 = payload
        },
        setAllResult: (state, { payload }) => {
            state.allResult = payload
        },
        setStep3: (state, { payload }) => {
            state.step3 = payload
        },
        setInputs: (state, { payload }) => {
            state.inputs = payload
        },
        setMediator: (state, { payload }) => {
            state.mediators = payload
        },
        setZoomCompanyList: (state, { payload }) => {
            state.zoomCompanyList = payload
        },
        resetZoomMeeting: (state) => {
            state.isCheckboxChecked = false
            state.isExpertCheckboxChecked = false
            state.isMediatorCheckboxChecked = false
            state.autoRecordingCheckboxChecked = true
            state.meetingLinkCheckboxChecked = false
            state.billingPartyCheckboxChecked = true
            state.msTeamCheckboxCheck = false
            state.zoomAccountList = []
            // state.zoomHostAccountList = []
            state.step1 = {
                client: "",
                engagement_type: "micro_consulting_call",
                location_address: "",
                project_name: "",
                call_name: "",
                meeting_link: "",
                meeting_id: "",
                meeting_passcode: "",
                project_id: "",
                call_date: "",
                call_time: "",
                call_time_string: "",
                contact_number: "",
                contact_code: "",
                country: {},
                timezone: "",
                abbreviation: "",
                duration: "",
                hourly_rate_call_cunsltn: "",
                hourly_currency: "USD",
                zoom_id: "",
                billing_client: ""
            }
            state.step3 = [{
                expert_name: null,
                expert_designation: null,
                expert_email: null,
                expert_hourly_rate_call_cunsltn: "",
                expert_hourly_currency: "USD",
                country: {},
                timezone: "",
                abbreviation: "",
                opt_out_check: false,
                additional_details: "",
                in_list: true,
            }]
            state.inputs = [
                {
                    client_name: "",
                    client_email: "",
                    in_list: true
                },
            ]
            state.mediators = [
                {
                    mediator_email: "",
                    mediator_name: "",
                    mediator_country: "",
                    mediator_timezone: "",
                    abbreviation: "",
                },
            ]
        },
        resetExpertsAndClients: (state) => {
            state.step3 = [{
                expert_name: null,
                expert_designation: null,
                expert_email: null,
                expert_hourly_rate_call_cunsltn: "",
                expert_hourly_currency: "USD",
                country: {},
                timezone: "",
                abbreviation: "",
                opt_out_check: false,
                additional_details: "",
                in_list: true,
            }]
            state.inputs = [
                {
                    client_name: "",
                    client_email: "",
                    in_list: true
                },
            ]
        }
    }

})
export const zoomMeetingReducer = zoomMeetingSlice.reducer
export const { setIsCheckboxChecked, setZoomAccountList, setStep1, setIsMediatorCheckboxChecked,
    setStep3, setInputs, resetZoomMeeting, setAllResult, setMsTeamCheckboxChecked, setZoomCompanyList, setZoomHostAccountList, setIsExpertCheckboxChecked, setUserProjectList, setZoomExpertList, setMediator, resetExpertsAndClients, setIsAutoRecordingCheckboxChecked, setIsBillingPartyCheckboxChecked, setIsMeetingLinkCheckboxChecked, setAllCompanyList } = zoomMeetingSlice.actions

